<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="兑奖码更新记录详情"
              ></el-page-header>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-row>
          <el-col>
            <div class="grid-content bg-purple">
              <el-form
                class="type-form"
                :model="edit_model"
                ref="detail_model"
                label-width="150px"
              >
                <el-card>
                  <div slot="header" class="clearfix">更改前</div>
                  <el-form-item label="兑奖码:">
                    <el-input
                      v-model.trim="edit_model.before_redeem_code"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="状态:">
                    <el-input
                      v-model.trim="edit_model.before_status"
                    ></el-input>
                  </el-form-item>
                </el-card>
                <el-card>
                  <div slot="header" class="clearfix">更改后</div>
                  <el-form-item label="兑奖码:">
                    <el-input
                      v-model.trim="edit_model.after_redeem_code"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="状态:">
                    <el-input v-model.trim="edit_model.after_status"></el-input>
                  </el-form-item>
                </el-card>

                <el-form-item label="生成时间:">
                  <el-input v-model.trim="edit_model.created_at"></el-input>
                </el-form-item>
                <el-form-item label="操作人:">
                  <el-input v-model.trim="edit_model.user"></el-input>
                </el-form-item>
                <el-form-item label="结果:">
                  <el-input v-model.trim="edit_model.result"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        before_status: 1000,
        after_status: 1000,
        user: "",
        before_redeem_code: "",
        after_redeem_code: "",
        result: "",
      },
      user_list: [],
      user_list_map: [],
    };
  },
  async mounted() {
    this.user_list_map = new Map();
    await this.getUserList();
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      await this.getEditModel();
    }
  },
  methods: {
    getUserList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.user_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.user_list) {
              _me.user_list_map.set(i.id, i.username);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/redeem_code/admin/update_record/find/id", params)
        .then((res) => {
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            _me.edit_model.user = this.user_list_map.get(res.data.user_id)
              ? _me.user_list_map.get(res.data.user_id)
              : "-";
            _me.edit_model.before_status =
              this.$common.reedemCommonStatusList.find(
                (item) => item.id === res.data.detail.status.before
              ).name;
            _me.edit_model.after_status =
              this.$common.reedemCommonStatusList.find(
                (item) => item.id === res.data.detail.status.after
              ).name;

            _me.edit_model.created_at = _me.$common.formatTimesToTime(
              res.data.created_at
            );
            _me.edit_model.before_redeem_code =
              res.data.detail.redeem_code.before;
            _me.edit_model.after_redeem_code =
              res.data.detail.redeem_code.after;
            _me.edit_model.result = res.data.result;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        })
        .finally(() => {
          _me.loading = false;
        });
    },

    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.type-form {
  margin: 0;
  max-width: 640px;
}
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
.el-card {
  margin-bottom: 20px;
}
.el-card >>> .el-card__body {
  padding-bottom: 0;
}
</style>
